import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { type Shift, ShiftPeriod } from "../../Shifts/Shift/types";

export type ShiftPeriodCount = Record<ShiftPeriod, number>;
export type ShiftsByDateAndPeriod = Record<string, ShiftPeriodCount>;

export function groupShiftsByDateAndPeriod(
  shifts: Shift[],
  workerTimezone: string
): ShiftsByDateAndPeriod {
  const normalizedShifts: ShiftsByDateAndPeriod = {};

  for (const shift of shifts) {
    const zonedDate = utcToZonedTime(parseISO(shift.start), workerTimezone);
    const startDate = format(zonedDate, "yyyy-MM-dd");
    const shiftName = shift.name;

    normalizedShifts[startDate] ||= {
      [ShiftPeriod.AM]: 0,
      [ShiftPeriod.PM]: 0,
      [ShiftPeriod.NOC]: 0,
    };

    normalizedShifts[startDate][shiftName] += 1;
  }

  return normalizedShifts;
}
