import { FileSharer } from "@byteowls/capacitor-filesharer";
import { isDefined, toError } from "@clipboard-health/util-ts";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { isPdfViewerSupportedOnPlatform } from "@src/appV2/PdfViewer/isPdfViewerSupportedOnPlatform";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import constate from "constate";
// eslint-disable-next-line n/no-extraneous-import
import type { PDFDocumentProxy } from "pdfjs-dist";
import { useState } from "react";

interface UsePdfViewerProps {
  fileName: string;
  pdfUrl: string | undefined;
}

const usePdfViewer = (props: UsePdfViewerProps) => {
  const { fileName, pdfUrl } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [documentProxy, setDocumentProxy] = useState<PDFDocumentProxy>();

  const onDocumentLoadSuccess = (documentProxy: PDFDocumentProxy) => {
    setDocumentProxy(documentProxy);

    logEvent(APP_V2_APP_EVENTS.PDF_LOAD_SUCCESS, {
      fileName,
    });
  };

  const { mutateAsync: downloadPdf, isLoading: isDownloading } = useMutation({
    mutationFn: async () => {
      if (!isDefined(fileName) || !isDefined(pdfUrl)) {
        throw new Error("Incorrectly initialized pdf viewer context");
      }

      const response = await axios.get<ArrayBuffer>(pdfUrl, { responseType: "arraybuffer" });

      try {
        const base64Data = Buffer.from(response.data).toString("base64");

        await FileSharer.share({
          filename: fileName,
          base64Data,
          contentType: "application/pdf",
        });

        logEvent(APP_V2_APP_EVENTS.PDF_DOWNLOADED, {
          fileName,
        });
      } catch (maybeError: unknown) {
        const error = toError(maybeError);

        logError(APP_V2_APP_EVENTS.PDF_DOWNLOAD_FAILURE, {
          error,
          metadata: {
            fileName,
          },
        });
      }
    },
  });

  const { data: isSupportedPlatform } = useQuery(["isPdfViewerSupportedOnPlatform"], async () => {
    try {
      return await isPdfViewerSupportedOnPlatform();
    } catch (error) {
      logError(APP_V2_APP_EVENTS.PDF_PLATFORM_CHECK_FAILURE, {
        error,
        metadata: {
          fileName,
        },
      });

      return false;
    }
  });

  return {
    currentPage,
    documentProxy,
    fileName,
    numberOfPages: documentProxy?.numPages,
    onDocumentLoadSuccess,
    setCurrentPage,
    isSupportedPlatform,
    downloadPdf,
    isDownloading,
    pdfUrl,
  };
};

export const [PdfViewerProvider, usePdfViewerContext] = constate(usePdfViewer);
