import { isDefined } from "@clipboard-health/util-ts";
import { type AgentFriendsResponse, type Friend } from "@src/appV2/Agents/api/useGetWorkerFriends";
import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import { uniqBy } from "lodash";

import { type ShiftSlotsResponse } from "../api/useGetShiftSlots";
import { type OpenShiftsResponse } from "../api/useOpenShifts";

interface GroupFriendsByShiftPeriodProps {
  openShifts: OpenShiftsResponse["openAgentShifts"];
  shiftSlots: ShiftSlotsResponse;
  workerFriends: AgentFriendsResponse;
}

export function groupFriendsByShiftPeriod(
  props: GroupFriendsByShiftPeriodProps
): Map<ShiftPeriod, Array<Pick<Friend, "friendId" | "name">>> {
  const { openShifts, shiftSlots, workerFriends } = props;
  if (shiftSlots.length === 0 || openShifts.length === 0 || workerFriends.length === 0) {
    return new Map([
      [ShiftPeriod.AM, []],
      [ShiftPeriod.PM, []],
      [ShiftPeriod.NOC, []],
    ]);
  }

  const friendsMap = new Map<ShiftPeriod, Array<Pick<Friend, "friendId" | "name">>>([
    [ShiftPeriod.AM, []],
    [ShiftPeriod.PM, []],
    [ShiftPeriod.NOC, []],
  ]);

  shiftSlots.forEach((shiftSlot) => {
    const { workplaceUserId, start, end, bookedBy } = shiftSlot;

    const shift = openShifts.find(
      (shift) =>
        shift.start === start.toString() &&
        shift.end === end.toString() &&
        shift.facilityId?.toString() === workplaceUserId
    );

    if (isDefined(shift)) {
      const friendIds = new Set(workerFriends.map((friend) => friend.workerId));
      const friends = bookedBy
        .filter((worker) => friendIds.has(worker.userId))
        .map((friend) => {
          return {
            name: friend.name,
            friendId: friend.userId,
          };
        });

      const currentFriends = friendsMap.get(shift.name) ?? [];
      const uniqueFriends = uniqBy([...currentFriends, ...friends], "friendId");

      friendsMap.set(shift.name, uniqueFriends);
    }
  });

  return friendsMap;
}
