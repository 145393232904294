import { z } from "zod";

const qualificationAttributesSchema = z.object({
  name: z.string(),
  displayLabel: z.string(),
  description: z.string(),
  enabled: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const qualificationSchema = z.object({
  type: z.literal("qualification"),
  id: z.string(),
  attributes: qualificationAttributesSchema,
});

export const qualificationsResponseSchema = z.object({
  data: z.array(qualificationSchema),
});

export const qualificationResponseSchema = z.object({
  data: qualificationSchema,
});

export type QualificationsResponse = z.infer<typeof qualificationsResponseSchema>;
export type QualificationResponse = z.infer<typeof qualificationResponseSchema>;
export type Qualification = z.infer<typeof qualificationSchema>;

/**
 * Qualifications with special conditional logic.
 *
 * Qualifications are dynamic and are configured via the Admin App UI.
 * This enum lists the names of the qualifications that have special conditional logic.
 */
export const SpecialQualifications = {
  CAREGIVER: "CAREGIVER",
  CNA: "CNA",
  LVN: "LVN",
  MEDICAL_ASSISTANT: "Medical Assistant",
  NON_CLINICAL: "Non Clinical",
  NURSE: "NURSE",
  PHLEBOTOMIST: "Phlebotomist",
  RN: "RN",
};
