import { type ShiftSlotsRequest } from "../api/useGetShiftSlots";
import { type OpenShiftsResponse } from "../api/useOpenShifts";
import { type AgentShiftResponse } from "../api/useWorkerShifts";

export function generateShiftSlots(
  agentShifts: AgentShiftResponse["agentShifts"],
  openShifts: OpenShiftsResponse["openAgentShifts"]
) {
  const shiftSlotsSet = new Set();
  return [...agentShifts, ...openShifts.filter((shift) => !shift?.filtered)].reduce<
    ShiftSlotsRequest["workplaceSlots"]
  >((accumulator, shift) => {
    const workplaceUserId = shift.facility.userId ?? "";
    const start = shift.start ?? "";
    const end = shift.end ?? "";
    const key = `${workplaceUserId},${start},${end}`;
    if (!shiftSlotsSet.has(key)) {
      shiftSlotsSet.add(key);
      accumulator.push({
        workplaceUserId,
        range: { start, end },
      });
    }

    return accumulator;
  }, []);
}
