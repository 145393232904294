import { SpecialQualifications } from "@clipboard-health/contract-worker-app-bff";

export const qualificationSubstitutions: Partial<
  Record<SpecialQualifications, SpecialQualifications[]>
> = {
  [SpecialQualifications.NURSE]: [SpecialQualifications.RN, SpecialQualifications.LVN],
  [SpecialQualifications.CAREGIVER]: [SpecialQualifications.CNA],
};

export function isValidQualificationName(
  qualificationName: string
): qualificationName is SpecialQualifications {
  return qualificationName in SpecialQualifications;
}

export function substituteQualification(
  qualificationName: string,
  workerOptions: string[]
): string {
  if (!isValidQualificationName(qualificationName)) {
    return qualificationName;
  }

  const substitutions = qualificationSubstitutions[qualificationName];
  const firstMatch = substitutions?.find((q) => workerOptions.includes(q));

  return firstMatch ?? qualificationName;
}
