import { isDefined } from "@clipboard-health/util-ts";
import { useWorkerQualifications } from "@src/appV2/Worker/useWorkerQualifications";

import { substituteQualification } from "../utils";
import { useGetQualifications } from "./useGetQualifications";

/**
 * Resolves the display label for a qualification.
 * If the qualification is not found, it returns the original qualification name.
 */
export function useGetQualificationDisplayLabel(qualificationName: string) {
  const { data, ...queryResult } = useGetQualifications({
    enabled: isDefined(qualificationName),
  });

  const workerQualifications = useWorkerQualifications();
  const substitutedQualificationName = substituteQualification(
    qualificationName,
    workerQualifications
  );

  return {
    data:
      data?.data.find((q) => q.attributes.name === substitutedQualificationName)?.attributes
        .displayLabel ?? substitutedQualificationName,
    ...queryResult,
  };
}
